var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "l-map",
    {
      class: { "is-disable-scroll": _vm.disableScroll },
      attrs: { center: _vm.latLngCenter, zoom: _vm.zoom },
      on: {
        ready: function ($event) {
          _vm.$emit("ready", $event), (_vm.map = $event)
        },
      },
    },
    [
      _vm._t("default"),
      _c("l-tile-layer", {
        attrs: {
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          attribution:
            "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
        },
      }),
      _vm.fullscreenable
        ? [
            _c(
              "l-control",
              [
                _c("base-button", {
                  attrs: {
                    btn2: "",
                    icon: _vm.isZenMode
                      ? "fas fa-compress-alt"
                      : "fas fa-expand-alt",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.SET_ZEN_MODE(!_vm.isZenMode)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }