var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container mat40" }, [
    _c("div", { staticClass: "row admin-header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-12 col-md-8" },
        [
          _c(
            "div",
            { staticClass: "buttons-wrap" },
            [
              _c(
                "base-button-group",
                {
                  staticClass: "admin-header__views",
                  attrs: { horizontal: "" },
                },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "admin-header__views-btn",
                      attrs: { btn11: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToSettings()
                        },
                      },
                    },
                    [_vm._v(" Dane organizatora ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "admin-header__views-btn",
                      attrs: { btn11: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToSettings()
                        },
                      },
                    },
                    [_vm._v(" Moje umowy ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "admin-header__views-btn",
                      attrs: {
                        btn11: "",
                        pressed:
                          _vm.$route.name === "panel-setting-new-info-payments",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({
                            name: "panel-setting-new-info-payments",
                          })
                        },
                      },
                    },
                    [_vm._v(" Płatności ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "admin-header__views-btn",
                      attrs: { btn11: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToSettings()
                        },
                      },
                    },
                    [_vm._v(" Wygląd ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "admin-header__views-btn",
                      attrs: { btn11: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToSettings()
                        },
                      },
                    },
                    [_vm._v(" Zaawansowane ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("router-view"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-4" }, [
      _c("h1", [_vm._v("Ustawienia profilu organizatora")]),
      _c("h3", [_vm._v("Potrzebujesz pomocy? Zadzwoń")]),
      _c("a", { staticClass: "call-now", attrs: { href: "tel:+502678564" } }, [
        _vm._v("502 678 564"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }