var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container mat40" }, [
    _vm._m(0),
    !_vm.ready
      ? _c("div", { staticClass: "mat32" }, [_vm._m(1)])
      : _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(2),
            _vm.data.length > 0
              ? _c(
                  "div",
                  { staticClass: "provisions" },
                  _vm._l(_vm.data, function (provision) {
                    return _c(
                      "div",
                      {
                        key: provision.id,
                        staticClass: "provision-item",
                        class: { expired: provision.overDate },
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "provision-name" }, [
                            _c("b", [
                              _vm._v(_vm._s(provision.competitionName)),
                            ]),
                          ]),
                        ]),
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              _vm._s(provision.lastCountedProvision) + " zł"
                            ),
                          ]),
                        ]),
                        provision.payed
                          ? _c("div", [_vm._v(" ")])
                          : provision.overDate
                          ? _c("div", [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M12.0581 3.26606C12.2459 2.91131 12.754 2.91131 12.9418 3.26606L21.9418 20.2661C22.1181 20.5991 21.8767 21 21.5 21H3.49995C3.12317 21 2.88176 20.5991 3.05806 20.2661L12.0581 3.26606ZM12.5 14C12.7761 14 13 13.7761 13 13.5V9.5C13 9.22386 12.7761 9 12.5 9C12.2238 9 12 9.22386 12 9.5V13.5C12 13.7761 12.2238 14 12.5 14ZM13 17V16H12V17H13ZM20.6695 20H4.33041L12.5 4.56863L20.6695 20Z",
                                      fill: "#979797",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                " Termin płatności upłynął " +
                                  _vm._s(provision.paymentDate.split("T")[0]) +
                                  " "
                              ),
                            ])
                          : _c("div", [
                              _vm._v(
                                "Termin płatności " +
                                  _vm._s(provision.paymentDate.split("T")[0])
                              ),
                            ]),
                        provision.payed
                          ? _c("div", [
                              _vm._v(
                                "opłacono " +
                                  _vm._s(provision.paymentDate.split("T")[0])
                              ),
                            ])
                          : _vm._e(),
                        _c("div", [_vm._v("  ")]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row admin-header" }, [
      _c("div", { staticClass: "col-12 col-md-4" }, [
        _c("h1", [_vm._v("Moje opłaty")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "admin-header__loading-state" }, [
          _vm._v(" Trwa wczytywanie "),
          _c("div", { staticClass: "mal15 lds-ellipsis" }, [
            _c("div"),
            _c("div"),
            _c("div"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "info-box" }, [
      _c("h4", [_vm._v("Inne metody płatności")]),
      _c("p", [
        _vm._v("Jeśli preferujesz inną metodę płatności,"),
        _c("br"),
        _vm._v(" skontaktuj się z nami "),
        _c("b", [_vm._v("987 654 321")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }