var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { overflow: "hidden" } },
    [
      !_vm.ready ? _c("main", [_vm._m(0)]) : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ready,
              expression: "ready",
            },
          ],
          staticClass: "stickyHeader",
          attrs: { "data-v-sticky-container": "" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "sticky",
                  rawName: "v-sticky",
                  value: _vm.stickyOptions,
                  expression: "stickyOptions",
                },
              ],
              ref: "stickyHeader",
            },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "panel-competition-list-toolbar",
                        class: { small: _vm.isZenMode },
                      },
                      [
                        _c(
                          "base-button-group",
                          {
                            attrs: {
                              horizontal: "",
                              border: false,
                              shadow: "",
                            },
                          },
                          [
                            _c("base-button", {
                              staticStyle: {
                                padding: "0",
                                "border-top-right-radius": "0",
                                "border-bottom-right-radius": "0",
                              },
                              attrs: {
                                btn9: "",
                                badge: _vm.isZenMode
                                  ? "dsi dsi-collapse-wide"
                                  : "dsi dsi-expand-wide",
                              },
                              on: { click: _vm.fullScreenTrigger },
                            }),
                            !_vm.isZenMode
                              ? _c("base-button", {
                                  staticStyle: {
                                    padding: "0",
                                    "border-top-left-radius": "0",
                                    "border-bottom-left-radius": "0",
                                  },
                                  attrs: { btn9: "", badge: "dsi dsi-cog" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.optionsModal.openModal()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "searchWrap",
                            class: { big: _vm.isZenMode },
                          },
                          [
                            _c("base-input-text-with-icon", {
                              staticClass: "minimal",
                              attrs: {
                                label: "Szukaj",
                                icon: "fas fa-search",
                                badge: "fas fa-times",
                              },
                              on: {
                                "icon-click": function ($event) {
                                  _vm.search = ""
                                },
                              },
                              model: {
                                value: _vm.search,
                                callback: function ($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search",
                              },
                            }),
                          ],
                          1
                        ),
                        !_vm.isZenMode
                          ? _c(
                              "div",
                              { staticClass: "btn-wrap" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: {
                                      btn9: "",
                                      icon: "dsi dsi-cloud-upload",
                                    },
                                    on: { click: _vm.openUploadModal },
                                  },
                                  [_vm._v(" Wgraj dane ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isZenMode
                          ? _c(
                              "div",
                              { staticClass: "btn-wrap" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: {
                                      btn9: "",
                                      icon: "dsi dsi-download",
                                    },
                                    on: { click: _vm.openDownloadModal },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Pobierz"),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "d-none d-md-inline-block",
                                          staticStyle: { "margin-left": "4px" },
                                        },
                                        [_vm._v("jako")]
                                      ),
                                      _vm._v("..."),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isZenMode
                          ? _c(
                              "div",
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: {
                                      btn9: "",
                                      icon: "dsi dsi-sort-numeric-down",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.navigateTo(
                                          "panel-competition-setting-numbers"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Nadaj numery ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "btn-add-user" },
                          [
                            _c(
                              "base-button",
                              {
                                class: { big: _vm.isZenMode },
                                staticStyle: {
                                  height: "40px",
                                  "box-sizing": "border-box",
                                },
                                attrs: { btn1: "" },
                                on: { click: _vm.openUserModal },
                              },
                              [_vm._v(" Dodaj uczestnika ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "container-result" },
            [
              _vm.playerListItems && _vm.playerListItems.length > 0
                ? _c("base-table", {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.onClickOutside,
                        expression: "onClickOutside",
                      },
                    ],
                    staticClass: "table-list",
                    attrs: {
                      headers: _vm.headersDefault,
                      items: _vm.playerListItems,
                      search: _vm.search,
                      sortColumnDefault: _vm.tablesort.column,
                      sortReverseDefault: _vm.tablesort.reverse,
                    },
                    on: {
                      "update:items": function ($event) {
                        _vm.playerListItems = $event
                      },
                      "update-sort": _vm.tableSortUpdate,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ sort }) {
                            return [
                              _c(
                                "tr",
                                { staticClass: "table-list__row bg--dark" },
                                _vm._l(
                                  _vm.headersDefault,
                                  function (header, column) {
                                    return _c(
                                      "th",
                                      {
                                        key: column,
                                        staticClass: "table-list__header",
                                        class: {
                                          "table-list__header--first-cell":
                                            header.value === "playerNumber",
                                          "table-list__header--min-width":
                                            header.value === "playerChip",
                                          "table-list__header--pay":
                                            header.value === "pay",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return sort(column)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(header.text) + " ")]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                        },
                        {
                          key: "body",
                          fn: function ({ displayedItems }) {
                            return _vm._l(
                              displayedItems,
                              function (item, index) {
                                return _c("PanelCompetitionListTableRow", {
                                  key: item.key,
                                  attrs: {
                                    item: item,
                                    "headers-default": _vm.headersDefault,
                                    competitionId: _vm.id,
                                    competitionName: _vm.$attrs.name,
                                    lp: index,
                                    index: item.id,
                                    openedIndex: _vm.openedIndex,
                                  },
                                  on: {
                                    "update:item": function ($event) {
                                      item = $event
                                    },
                                    openUserModal: _vm.openUserModal,
                                    openPayLinkModal: _vm.openPayLinkModal,
                                    "change-accordion": (newOpen) =>
                                      (_vm.openedIndex = newOpen),
                                    update: _vm.updatePlayer,
                                  },
                                })
                              }
                            )
                          },
                        },
                        {
                          key: "no-results",
                          fn: function ({ search: term }) {
                            return [
                              _c("p", { staticClass: "bg--gray pa16" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.say.no_search_results) + " "
                                ),
                                _c("b", [_vm._v(_vm._s(term))]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2304700640
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("modal", {
        ref: "optionsModal",
        attrs: { id: "optionsModal", bodyClass: "bg" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Dane wyświetlane na liście startowej")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 col-md-6" }, [
                      _c("h4", [_vm._v("Informacje podstawowe")]),
                      _c(
                        "div",
                        { staticClass: "checkboxWrap" },
                        _vm._l(_vm.headers, function (header, index) {
                          return _c(
                            "BaseInputCheckbox",
                            {
                              key: index,
                              attrs: {
                                "checkbox-value": _vm.headers[index].checked,
                                name: `agreement_ids[${index}]`,
                              },
                              model: {
                                value: _vm.headers[index].checked,
                                callback: function ($$v) {
                                  _vm.$set(_vm.headers[index], "checked", $$v)
                                },
                                expression: "headers[index].checked",
                              },
                            },
                            [_vm._v(_vm._s(header.text))]
                          )
                        }),
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-12 col-md-6 pr-24" }, [
                      _vm.inputsAdditionalInformation &&
                      _vm.inputsAdditionalInformation.length > 0
                        ? _c("div", [
                            _c("h4", [_vm._v("Informacje dodatkowe")]),
                            _c(
                              "div",
                              { staticClass: "checkboxWrap" },
                              _vm._l(
                                _vm.inputsAdditionalInformation,
                                function (input, index) {
                                  return _c(
                                    "BaseInputCheckbox",
                                    {
                                      key: index + "add",
                                      attrs: {
                                        "checkbox-value": {
                                          value: input.id,
                                          text: input.fNamePl,
                                        },
                                        name: `input_ids_add[${index}]`,
                                      },
                                      model: {
                                        value: _vm.additionalHeaders,
                                        callback: function ($$v) {
                                          _vm.additionalHeaders = $$v
                                        },
                                        expression: "additionalHeaders",
                                      },
                                    },
                                    [_vm._v(_vm._s(input.fNamePl))]
                                  )
                                }
                              ),
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mab24" },
                        [
                          _c("h4", [_vm._v("Informacje pomocnicze")]),
                          _c("p", [
                            _vm._v(
                              "Zaznaczone informacje pomocnicze pojawią się na liście startowej. Nie są widoczne dla uczestników"
                            ),
                          ]),
                          _vm._l(
                            _vm.inputsSupportInformation,
                            function (input, index) {
                              return _c(
                                "div",
                                { staticClass: "checkboxInput" },
                                [
                                  _c("BaseInputCheckbox", {
                                    key: index + "sup",
                                    ref: `input_ids_sup_${index}`,
                                    refInFor: true,
                                    attrs: {
                                      "checkbox-value": {
                                        value: input.id,
                                        text: input.fNamePl,
                                      },
                                      name: `input_ids_sup[${index}]`,
                                    },
                                    model: {
                                      value: input.checked,
                                      callback: function ($$v) {
                                        _vm.$set(input, "checked", $$v)
                                      },
                                      expression: "input.checked",
                                    },
                                  }),
                                  _c("base-input-text", {
                                    key: index + "supi",
                                    attrs: {
                                      hint: "Nazwa kolumny z informacją pomocniczą",
                                    },
                                    model: {
                                      value: input.fNamePl,
                                      callback: function ($$v) {
                                        _vm.$set(input, "fNamePl", $$v)
                                      },
                                      expression: "input.fNamePl",
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c("div", [
                        _c("h4", [
                          _vm._v("Informacja dodatkowa dla uczestnika"),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Dodaj specjalną kolumnę na liście startowej, aby ponformować uczestników o sektorze, lub fali, w której startują. Informacje uzupełnij w edycji uczestnika na liście startowej."
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "checkboxInput" },
                          [
                            _c("BaseInputCheckbox", {
                              attrs: { name: "frontColumnCheck" },
                              model: {
                                value: _vm.frontColumn.checked,
                                callback: function ($$v) {
                                  _vm.$set(_vm.frontColumn, "checked", $$v)
                                },
                                expression: "frontColumn.checked",
                              },
                            }),
                            _c("base-input-text", {
                              attrs: {
                                hint: "Nazwa kolumny z informacją pomocniczą",
                              },
                              model: {
                                value: _vm.frontColumn.text,
                                callback: function ($$v) {
                                  _vm.$set(_vm.frontColumn, "text", $$v)
                                },
                                expression: "frontColumn.text",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "base-button",
                  {
                    staticClass: "btn-short",
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.optionsModal.closeModal()
                      },
                    },
                  },
                  [_vm._v("Anuluj")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "btn-long",
                    attrs: { btn1: "" },
                    on: { click: _vm.saveDefaultHeaders },
                  },
                  [_vm._v("Zapisz")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal", {
        ref: "uploadModal",
        attrs: {
          id: "uploadModal",
          bodyClass: "h-auto",
          "disabled-backdrop": "",
        },
        on: { "modal-close": _vm.defaultUploadModalData },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Wgraj dane")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.uploadDataContainer === "list"
                  ? [
                      _vm.uploadListLoader
                        ? _c("div", { staticClass: "loader" }, [
                            _c("div", { staticClass: "lds-default" }, [
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                            ]),
                            _c("p", [
                              _vm._v("Sprawdzamy poprawność danych"),
                              _c("br", { staticClass: "d-none d-md-block" }),
                              _vm._v(" i importujemy listę"),
                            ]),
                          ])
                        : _vm.uploadSuccess
                        ? _c("div", { staticClass: "up-wrap" }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/images/icons/checkmark-circle.svg"),
                              },
                            }),
                            _c("p", { staticClass: "text-lg" }, [
                              _c("b", [
                                _vm._v(
                                  "Lista startowa została poprawnie zaimportowana"
                                ),
                              ]),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Znależliśmy " +
                                  _vm._s(_vm.importListLength) +
                                  " uczestników"
                              ),
                            ]),
                          ])
                        : _vm.uploadError
                        ? _c("div", { staticClass: "up-wrap" }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/images/icons/close-circle.svg"),
                              },
                            }),
                            _c("p", { staticClass: "text-lg" }, [
                              _c("b", [
                                _vm._v("Nie udało się zaimportować listy"),
                              ]),
                            ]),
                            _vm.uploadErrorMsg
                              ? _c("p", [_vm._v(_vm._s(_vm.uploadErrorMsg))])
                              : _c("p", [
                                  _vm._v(
                                    "Aby zaimportować listę, wybierz kolumny"
                                  ),
                                  _c("br", {
                                    staticClass: "d-none d-md-block",
                                  }),
                                  _vm._v("z "),
                                  _c("b", [
                                    _vm._v(
                                      "imieniem, nazwiskiem, datą urodzenia i płcią"
                                    ),
                                  ]),
                                ]),
                          ])
                        : _vm.uploadListData.length > 0
                        ? [
                            _c("panel-upload-table", {
                              key: _vm.uploadListDataKey,
                              attrs: {
                                data: _vm.uploadListData,
                                role: _vm.uploadDataContainer,
                              },
                              on: {
                                change: (headers) =>
                                  (_vm.headersForUploadList = headers),
                              },
                            }),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isUploadListWrapOpen,
                              expression: "isUploadListWrapOpen",
                            },
                          ],
                          staticClass: "up-wrap",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/images/icons/upload.svg"),
                            },
                          }),
                          _c("input", {
                            ref: "uploadFile",
                            staticClass: "input-field__input-file",
                            attrs: { type: "file" },
                            on: {
                              change: function ($event) {
                                return _vm.uploadListInputFileHandler($event)
                              },
                            },
                          }),
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.uploadFile.click()
                                },
                              },
                            },
                            [_vm._v("Wybierz plik CSV")]
                          ),
                          _c("p", [
                            _vm._v("Sprawdź jak powinien wyglądać poprawnie"),
                            _c("br", { staticClass: "d-none d-md-block" }),
                            _vm._v(" sformatowany plik z wynikami."),
                          ]),
                          _c("p", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://dostartu.pl/files/demo/demo_playerslist_import.csv",
                                },
                              },
                              [_vm._v("pobierz przykładowy plik")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : _vm.uploadDataContainer === "chip"
                  ? [
                      _vm.uploadListLoader
                        ? _c("div", { staticClass: "loader" }, [
                            _c("div", { staticClass: "lds-default" }, [
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                              _c("div"),
                            ]),
                            _c("p", [
                              _vm._v("Sprawdzamy poprawność danych"),
                              _c("br", { staticClass: "d-none d-md-block" }),
                              _vm._v(" i importujemy listę"),
                            ]),
                          ])
                        : _vm.uploadSuccess
                        ? _c("div", { staticClass: "up-wrap" }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/images/icons/checkmark-circle.svg"),
                              },
                            }),
                            _c("p", { staticClass: "text-lg" }, [
                              _c("b", [
                                _vm._v(
                                  "Lista startowa została poprawnie zaimportowana"
                                ),
                              ]),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Znależliśmy " +
                                  _vm._s(_vm.importListLength) +
                                  " uczestników"
                              ),
                            ]),
                          ])
                        : _vm.uploadError
                        ? _c("div", { staticClass: "up-wrap" }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/images/icons/close-circle.svg"),
                              },
                            }),
                            _c("p", { staticClass: "text-lg" }, [
                              _c("b", [
                                _vm._v("Nie udało się zaimportować listy"),
                              ]),
                            ]),
                            _vm.uploadErrorMsg
                              ? _c("p", [_vm._v(_vm._s(_vm.uploadErrorMsg))])
                              : _c("p", [
                                  _vm._v(
                                    "Aby zaimportować listę, wybierz kolumny"
                                  ),
                                  _c("br", {
                                    staticClass: "d-none d-md-block",
                                  }),
                                  _vm._v("z "),
                                  _c("b", [
                                    _vm._v("numerem startowym i numerem chip."),
                                  ]),
                                ]),
                          ])
                        : _vm.uploadListData.length > 0
                        ? [
                            _c("panel-upload-table", {
                              key: _vm.uploadListDataKey,
                              attrs: {
                                data: _vm.uploadListData,
                                "headers-data": _vm.chipHeadersData,
                                role: _vm.uploadDataContainer,
                              },
                              on: {
                                change: (headers) =>
                                  (_vm.headersForUploadList = headers),
                              },
                            }),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isUploadListWrapOpen,
                              expression: "isUploadListWrapOpen",
                            },
                          ],
                          staticClass: "up-wrap",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/images/icons/upload.svg"),
                            },
                          }),
                          _c("input", {
                            ref: "uploadFile",
                            staticClass: "input-field__input-file",
                            attrs: { type: "file" },
                            on: {
                              change: function ($event) {
                                return _vm.uploadListInputFileHandler($event)
                              },
                            },
                          }),
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.uploadFile.click()
                                },
                              },
                            },
                            [_vm._v("Wybierz plik CSV")]
                          ),
                          _c("p", [
                            _vm._v("Sprawdź jak powinien wyglądać poprawnie"),
                            _c("br", { staticClass: "d-none d-md-block" }),
                            _vm._v(" sformatowany plik z wynikami."),
                          ]),
                          _c("p", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://dostartu.pl/files/demo/demo_playerslist_import.csv",
                                },
                              },
                              [_vm._v("pobierz przykładowy plik")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isUploadListWrapOpen,
                              expression: "!isUploadListWrapOpen",
                            },
                          ],
                          staticClass: "dl-wrap",
                        },
                        [
                          _c("div", { staticClass: "dl-item" }, [
                            _c("div", [
                              _c("h4", [_vm._v("Wgraj listę startową")]),
                              _c("p", [
                                _vm._v(
                                  "Wgraj plik CSV z kolumnami zawierającymi imię, nazwisko, datę urodzenia i płeć."
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: { btn9: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadWrapTrigger("list")
                                      },
                                    },
                                  },
                                  [_vm._v(" Wgraj listę startową ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "dl-item" }, [
                            _c("div", [
                              _c("h4", [_vm._v("Wgraj numery chipów")]),
                              _c("p", [
                                _vm._v(
                                  "Wgraj plik CSV z kolumnami zawierającymi numer startowy i numer chip."
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: { btn9: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadWrapTrigger("chip")
                                      },
                                    },
                                  },
                                  [_vm._v(" Wgraj listę numerów chip ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _vm.uploadSuccess
                  ? [
                      _c("div", [_vm._v(" ")]),
                      _c(
                        "base-button",
                        {
                          staticClass: "btn-short",
                          attrs: { btn9: "" },
                          on: { click: _vm.refreshTable },
                        },
                        [_vm._v("Wróć do listy startowej")]
                      ),
                    ]
                  : _vm.uploadError
                  ? [
                      _c("div", [_vm._v(" ")]),
                      _c(
                        "base-button",
                        {
                          staticClass: "btn-short",
                          attrs: { btn9: "" },
                          on: {
                            click: function ($event) {
                              _vm.uploadError = false
                            },
                          },
                        },
                        [_vm._v("Wróć do wyboru kolumn")]
                      ),
                    ]
                  : !_vm.uploadListLoader
                  ? [
                      _vm.uploadDataContainer === "list"
                        ? [
                            _c(
                              "base-button",
                              {
                                staticClass: "btn-short",
                                attrs: { btn9: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.uploadModal.closeModal()
                                  },
                                },
                              },
                              [_vm._v("Anuluj")]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !_vm.uploadSuccess &&
                                      _vm.uploadListData.length > 0,
                                    expression:
                                      "!uploadSuccess && uploadListData.length > 0",
                                  },
                                ],
                                staticClass: "btn-wrap",
                              },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "btn-short",
                                    attrs: { btn9: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadListTrigger(true)
                                      },
                                    },
                                  },
                                  [_vm._v("Nadpisz listę")]
                                ),
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "btn-long",
                                    attrs: { btn1: "" },
                                    on: { click: _vm.uploadListTrigger },
                                  },
                                  [_vm._v("Wgraj LISTĘ STARTOWĄ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm.uploadDataContainer === "chip"
                        ? [
                            _c(
                              "base-button",
                              {
                                staticClass: "btn-short",
                                attrs: { btn9: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.uploadModal.closeModal()
                                  },
                                },
                              },
                              [_vm._v("Anuluj")]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !_vm.uploadSuccess &&
                                      _vm.uploadListData.length > 0,
                                    expression:
                                      "!uploadSuccess && uploadListData.length > 0",
                                  },
                                ],
                                staticClass: "btn-wrap",
                              },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "btn-short",
                                    attrs: { btn9: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadChipTrigger(true)
                                      },
                                    },
                                  },
                                  [_vm._v("Nadpisz numery")]
                                ),
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "btn-long",
                                    attrs: { btn1: "" },
                                    on: { click: _vm.uploadChipTrigger },
                                  },
                                  [_vm._v("Wgraj NUMERY CHIP")]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "base-button",
                              {
                                staticClass: "btn-short",
                                attrs: { btn9: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.uploadModal.closeModal()
                                  },
                                },
                              },
                              [_vm._v("Anuluj")]
                            ),
                          ],
                    ]
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal", {
        ref: "downloadModal",
        attrs: { bodyClass: "h-auto" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Pobierz listę startową jako...")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "dl-info-wrap",
                    class: { "is-waiting": _vm.isWaiting },
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-col form-col-8" }, [
                        _c("h4", [_vm._v("Kodowanie pobieranych plików")]),
                        _c("p", [
                          _vm._v(
                            "Jeśli na liście pojawiają się Imiona, Nazwiska i inne nazwy w obcych alfabetach, wybierz kodowanie UTF8"
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-col form-col-4" },
                        [
                          _c("h4", { staticStyle: { "min-height": "8px" } }, [
                            _vm._v(" "),
                          ]),
                          _c("panel-input-select", {
                            attrs: {
                              options: _vm.unicodeForSelect,
                              "hide-selected": false,
                            },
                            model: {
                              value: _vm.unicodeForDownload,
                              callback: function ($$v) {
                                _vm.unicodeForDownload = $$v
                              },
                              expression: "unicodeForDownload",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "dl-wrap",
                    class: { "is-waiting": _vm.isWaiting },
                  },
                  [
                    _c("div", { staticClass: "dl-item" }, [
                      _c("div", [
                        _c("h4", [_vm._v("Plik PDF")]),
                        _c("p", [
                          _vm._v(
                            "sformatowana do wydruku lista uczestników z numerami startowymi"
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCompetition("pdf")
                                },
                              },
                            },
                            [_vm._v(" Lista alfabetyczna ")]
                          ),
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCompetition("pdf-numbers")
                                },
                              },
                            },
                            [_vm._v(" Lista wg. numerów ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "dl-item" }, [
                      _c("div", [
                        _c("h4", [_vm._v("Plik XLS")]),
                        _c("p", [_vm._v("format MS Excel")]),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCompetition("xls")
                                },
                              },
                            },
                            [_vm._v(" Pełna lista ")]
                          ),
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCompetition("xls-headers")
                                },
                              },
                            },
                            [_vm._v(" Tylko widoczne kolumny ")]
                          ),
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCompetition("xls-gep")
                                },
                              },
                            },
                            [_vm._v(" Dla urządzeń Gepard ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "dl-item" }, [
                      _c("div", [
                        _c("h4", [_vm._v("Plik CSV")]),
                        _c("p", [
                          _vm._v(
                            "obsługiwany przez większość arkuszy kalkulacyjnych"
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCompetition("csv")
                                },
                              },
                            },
                            [_vm._v(" Pełna lista ")]
                          ),
                          _c(
                            "base-button",
                            {
                              attrs: { btn9: "", icon: "dsi dsi-download" },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadCompetition("csv-del")
                                },
                              },
                            },
                            [_vm._v(" Usunięci uczestnicy ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm.isWaiting
                  ? _c("div", { staticClass: "loading" }, [
                      _c(
                        "div",
                        { staticClass: "lds-ellipsis lds-ellipsis--center" },
                        [_c("div"), _c("div"), _c("div")]
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "base-button",
                  {
                    staticClass: "btn-short",
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.downloadModal.closeModal()
                      },
                    },
                  },
                  [_vm._v("Anuluj")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal", {
        ref: "addNewUser",
        attrs: { id: "userModal", bodyClass: "bg  h-auto visible", size: "xl" },
        on: { "modal-close": _vm.defaultUserModalData },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm.form && _vm.form.id
                  ? _c("span", [_vm._v("Edytuj uczestnika")])
                  : _c("span", [_vm._v("Dodaj uczestnika")]),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("ValidationObserver", {
                  ref: "newPlayerFormObserver",
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ handleSubmit }) {
                        return [
                          _c(
                            "form",
                            {
                              staticClass: "modal-body",
                              class: { "is-waiting": _vm.isWaiting },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return handleSubmit(_vm.formSubmit)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "form-row out-gutter" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-col form-col-8 border-right",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-row mab16sm" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-col form-col-3",
                                            },
                                            [
                                              _c("ValidationProvider", {
                                                staticClass: "scroll-margin",
                                                attrs: {
                                                  name: "firstname",
                                                  rules: "required",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "panel-input-text",
                                                            {
                                                              staticClass:
                                                                "mab16",
                                                              attrs: {
                                                                label: "Imię",
                                                                error:
                                                                  errors[0],
                                                              },
                                                              on: {
                                                                "update:error":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      errors,
                                                                      0,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .firstname,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "firstname",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.firstname",
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-col form-col-9",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-col form-col-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "lastname",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "panel-input-autocomplete",
                                                                    {
                                                                      ref: "formLastname",
                                                                      staticClass:
                                                                        "mab16",
                                                                      attrs: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .lastname,
                                                                        label:
                                                                          "Nazwisko",
                                                                        optionLabel:
                                                                          "lastname",
                                                                        action:
                                                                          "AUTOCOMPLETE_COMPETITION_PLAYER",
                                                                        "action-id":
                                                                          _vm
                                                                            .competition
                                                                            .organizer,
                                                                        error:
                                                                          errors[0],
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          _vm.changeLastname,
                                                                        select:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.selectPlayer(
                                                                              $event
                                                                            )
                                                                          },
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "option-data",
                                                                              fn: function (
                                                                                option
                                                                              ) {
                                                                                return [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        option.lastname +
                                                                                          " " +
                                                                                          option.firstname
                                                                                      )
                                                                                  ),
                                                                                  _c(
                                                                                    "br"
                                                                                  ),
                                                                                  _c(
                                                                                    "small",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          "ur. " +
                                                                                            _vm.birthdayFormatDate(
                                                                                              option.birthTime
                                                                                            )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .lastname,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.form,
                                                                              "lastname",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.lastname",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-col form-col-6",
                                                    },
                                                    [
                                                      _c("ValidationProvider", {
                                                        staticClass:
                                                          "scroll-margin",
                                                        attrs: {
                                                          name: "gender",
                                                          rules: "required",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "default",
                                                              fn: function ({
                                                                errors,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "panel-input-radio",
                                                                    {
                                                                      staticClass:
                                                                        "mab16",
                                                                      attrs: {
                                                                        name: "formGender",
                                                                        label:
                                                                          "Płeć",
                                                                        options:
                                                                          [
                                                                            {
                                                                              value:
                                                                                "M",
                                                                              title:
                                                                                "man",
                                                                            },
                                                                            {
                                                                              value:
                                                                                "K",
                                                                              title:
                                                                                "woman",
                                                                            },
                                                                          ],
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .gender,
                                                                        error:
                                                                          errors[0],
                                                                      },
                                                                      on: {
                                                                        "update:error":
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.$set(
                                                                              errors,
                                                                              0,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .gender,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.form,
                                                                              "gender",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "form.gender",
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-3",
                                          },
                                          [
                                            _c("ValidationProvider", {
                                              staticClass: "scroll-margin",
                                              attrs: {
                                                name: "birthTime",
                                                rules:
                                                  "required|birthdayValidator",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c("panel-input-date", {
                                                          staticClass: "mab16",
                                                          attrs: {
                                                            value:
                                                              _vm.form
                                                                .birthTime,
                                                            label:
                                                              "Data urodzenia",
                                                            placeholder:
                                                              "dd.mm.yyyy",
                                                            error: errors[0],
                                                          },
                                                          on: {
                                                            "update:error":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  errors,
                                                                  0,
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .birthTime,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "birthTime",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.birthTime",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-9",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("ValidationProvider", {
                                                      staticClass:
                                                        "scroll-margin",
                                                      attrs: {
                                                        name: "city",
                                                        rules: "required",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "panel-input-text",
                                                                  {
                                                                    staticClass:
                                                                      "mab16",
                                                                    attrs: {
                                                                      label:
                                                                        "Miejscowość",
                                                                      error:
                                                                        errors[0],
                                                                    },
                                                                    on: {
                                                                      "update:error":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            errors,
                                                                            0,
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .city,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "city",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "form.city",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("panel-input-text", {
                                                      staticClass: "mab16",
                                                      attrs: {
                                                        label: "Kraj",
                                                        error:
                                                          _vm.errors.country,
                                                      },
                                                      on: {
                                                        "update:error":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.errors,
                                                              "country",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value: _vm.form.country,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "country",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.country",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-3",
                                          },
                                          [
                                            _c("panel-input-text", {
                                              staticClass: "mab16",
                                              attrs: {
                                                type: "number",
                                                label: "Telefon",
                                                error: _vm.errors.phone,
                                              },
                                              on: {
                                                "update:error": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.errors,
                                                    "phone",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "phone",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.phone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-9",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("panel-input-text", {
                                                      staticClass: "mab16",
                                                      attrs: {
                                                        label: "Adres-email",
                                                        error: _vm.errors.email,
                                                      },
                                                      on: {
                                                        "update:error":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.errors,
                                                              "email",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value: _vm.form.email,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.email",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("panel-input-text", {
                                                      staticClass: "mab16",
                                                      attrs: {
                                                        label: "Klub",
                                                        error:
                                                          _vm.errors.clubname,
                                                      },
                                                      on: {
                                                        "update:error":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.errors,
                                                              "clubname",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.clubname,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "clubname",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.clubname",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-3",
                                          },
                                          [
                                            _c("panel-input-text", {
                                              staticClass: "mab8",
                                              attrs: {
                                                label: "Telefon ICE",
                                                error: _vm.errors.icePhone,
                                              },
                                              on: {
                                                "update:error": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.errors,
                                                    "icePhone",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.icePhone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "icePhone",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.icePhone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-9",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("panel-input-select", {
                                                      staticClass: "mab16",
                                                      attrs: {
                                                        options:
                                                          _vm.playerStatus,
                                                        clearable: false,
                                                        label: "name",
                                                        trackBy: "name",
                                                        selectLabel:
                                                          "Wybierz status",
                                                        error:
                                                          _vm.errors.status,
                                                      },
                                                      on: {
                                                        "update:error":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.errors,
                                                              "status",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value: _vm.form.status,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "status",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.status",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("panel-input-switch", {
                                                      staticClass: "mab16",
                                                      attrs: {
                                                        value:
                                                          _vm.form.isAnonymous,
                                                        name: "formisAnonymous",
                                                        label: "Anonimowy",
                                                        options: [
                                                          {
                                                            value: true,
                                                            title: "Tak",
                                                          },
                                                          {
                                                            value: false,
                                                            title: "Nie",
                                                          },
                                                        ],
                                                        error:
                                                          _vm.errors
                                                            .isAnonymous,
                                                      },
                                                      on: {
                                                        "update:error":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.errors,
                                                              "isAnonymous",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.isAnonymous,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "isAnonymous",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.isAnonymous",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "hr__line" }, [
                                        _vm._v(" "),
                                      ]),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-3",
                                          },
                                          [
                                            _c("panel-input-text", {
                                              staticClass: "mab16",
                                              attrs: {
                                                label: "Numer startowy",
                                                error: _vm.errors.playerNumber,
                                              },
                                              on: {
                                                "update:error": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.errors,
                                                    "playerNumber",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.playerNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "playerNumber",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.playerNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-9",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("ValidationProvider", {
                                                      attrs: {
                                                        name: "classification",
                                                        rules: "required",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function ({
                                                              errors,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "panel-input-select",
                                                                  {
                                                                    staticClass:
                                                                      "mab16",
                                                                    attrs: {
                                                                      options:
                                                                        _vm.classificationsForSelect,
                                                                      selectLabel:
                                                                        "Klasyfikacja",
                                                                      "hide-selected": false,
                                                                      error:
                                                                        errors[0],
                                                                    },
                                                                    on: {
                                                                      "update:error":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.$set(
                                                                            errors,
                                                                            0,
                                                                            $event
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.placeClassification,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.placeClassification =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "placeClassification",
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _vm.specialCategories &&
                                                    _vm.specialCategories
                                                      .length > 0
                                                      ? _c(
                                                          "panel-input-select",
                                                          {
                                                            staticClass:
                                                              "mab16",
                                                            attrs: {
                                                              options:
                                                                _vm.specialCategories,
                                                              selectLabel:
                                                                "Kategoria specjalna",
                                                              multiple: "",
                                                              searchable: "",
                                                              "hide-selected": false,
                                                              error:
                                                                _vm.errors
                                                                  .category,
                                                            },
                                                            on: {
                                                              "update:error":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.errors,
                                                                    "category",
                                                                    $event
                                                                  )
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .category,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "category",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.category",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "form-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-3",
                                          },
                                          [
                                            _c("panel-input-text", {
                                              staticClass: "mab24",
                                              attrs: {
                                                label: "Nr GPS",
                                                error:
                                                  _vm.errors.playerGpsNumber,
                                              },
                                              on: {
                                                "update:error": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.errors,
                                                    "playerGpsNumber",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.playerGpsNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "playerGpsNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.playerGpsNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-col form-col-9",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _c("panel-input-text", {
                                                      staticClass: "mab24",
                                                      attrs: {
                                                        label: "Numer chipa",
                                                        error:
                                                          _vm.errors.playerChip,
                                                      },
                                                      on: {
                                                        "update:error":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.errors,
                                                              "playerChip",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.playerChip,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "playerChip",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.playerChip",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-col form-col-6",
                                                  },
                                                  [
                                                    _vm.placeClassification &&
                                                    _vm.placeClassification
                                                      .classificationPrices
                                                      .length > 0
                                                      ? _c(
                                                          "panel-input-switch",
                                                          {
                                                            staticClass:
                                                              "mab24",
                                                            attrs: {
                                                              value:
                                                                _vm.form.pay,
                                                              name: "formStatus",
                                                              label:
                                                                "Opłata za udział",
                                                              options: [
                                                                {
                                                                  value: true,
                                                                  title:
                                                                    "Opłacony",
                                                                },
                                                                {
                                                                  value: false,
                                                                  title:
                                                                    "Nieopłacony",
                                                                },
                                                              ],
                                                              error:
                                                                _vm.errors.pay,
                                                            },
                                                            on: {
                                                              "update:error":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.errors,
                                                                    "pay",
                                                                    $event
                                                                  )
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form.pay,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "pay",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.pay",
                                                            },
                                                          }
                                                        )
                                                      : _vm.placeClassification &&
                                                        _vm.placeClassification
                                                          .classificationPrices
                                                          .length === 0
                                                      ? _c("div", [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mab8",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Opłata za udział"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Klasyfikacja bezpłatna"
                                                            ),
                                                          ]),
                                                        ])
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "d-md-none hr__line" },
                                        [_vm._v(" ")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-col form-col-4 border-left",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ps-wrap",
                                          staticStyle: { display: "none" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "buttons-wrap" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "btn",
                                                  class: {
                                                    "is-active":
                                                      _vm.buttonsTabs ===
                                                      "additional-fields",
                                                  },
                                                  attrs: {
                                                    id: "additional-fields",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showCompetitionPanel(
                                                        "additional-fields"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "icon" },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("../assets/images/icons/add-to-list.svg"),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [_vm._v("Pola dodatkowe")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.form.id &&
                                                        _vm.playerCycle.length >
                                                          0,
                                                      expression:
                                                        "form.id && playerCycle.length > 0",
                                                    },
                                                  ],
                                                  staticClass: "btn",
                                                  class: {
                                                    "is-active":
                                                      _vm.buttonsTabs ===
                                                      "competition-cycle",
                                                  },
                                                  attrs: {
                                                    id: "competition-cycle",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showCompetitionPanel(
                                                        "competition-cycle"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "icon" },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("../assets/images/icons/stopper.svg"),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [_vm._v("Zawody w cyklu")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.form.id &&
                                                        _vm.playerShop.length >
                                                          0,
                                                      expression:
                                                        "form.id && playerShop.length > 0",
                                                    },
                                                  ],
                                                  staticClass: "btn",
                                                  class: {
                                                    "is-active":
                                                      _vm.buttonsTabs ===
                                                      "orders",
                                                  },
                                                  attrs: { id: "orders" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showCompetitionPanel(
                                                        "orders"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "icon" },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: require("../assets/images/icons/shopping-cart.svg"),
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [_vm._v("Zamówienia")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "perfect-scrollbar",
                                        { ref: "userModalScroll" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "buttons-content-wrap",
                                              class: {
                                                "is-active":
                                                  _vm.buttonsTabs !== null,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "buttons-content",
                                                  class: {
                                                    "is-active":
                                                      _vm.buttonsTabs ===
                                                      "additional-fields",
                                                  },
                                                },
                                                [
                                                  _vm.inputsAdditionalInformation &&
                                                  _vm
                                                    .inputsAdditionalInformation
                                                    .length > 0
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.inputsAdditionalInformation,
                                                          function (
                                                            input,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "panel-input-wrap",
                                                              {
                                                                key:
                                                                  input.id +
                                                                  "_" +
                                                                  index +
                                                                  "wrapAddUsr",
                                                                attrs: {
                                                                  data: input,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .playerInputs[
                                                                      input.id
                                                                    ].value,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form
                                                                          .playerInputs[
                                                                          input
                                                                            .id
                                                                        ],
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.playerInputs[input.id].value",
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.inputsSupportFormUser &&
                                                  _vm.inputsSupportFormUser
                                                    .length > 0
                                                    ? _c(
                                                        "div",
                                                        _vm._l(
                                                          _vm.inputsSupportFormUser,
                                                          function (
                                                            input,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "panel-input-wrap",
                                                              {
                                                                key:
                                                                  input.id +
                                                                  "_" +
                                                                  index +
                                                                  "wrapAddSuppUsr",
                                                                attrs: {
                                                                  data: input,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .playerInputs[
                                                                      input.id
                                                                    ].value,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form
                                                                          .playerInputs[
                                                                          input
                                                                            .id
                                                                        ],
                                                                        "value",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.playerInputs[input.id].value",
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.competition.frontColumn
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "panel-input-area",
                                                            {
                                                              staticClass:
                                                                "mab24",
                                                              attrs: {
                                                                label:
                                                                  _vm
                                                                    .competition
                                                                    .frontColumn,
                                                                rows: 4,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .frontInfo,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "frontInfo",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.frontInfo",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("panel-input-area", {
                                                    staticClass: "mab24",
                                                    attrs: {
                                                      label: "Notatka",
                                                      rows: 4,
                                                    },
                                                    model: {
                                                      value: _vm.form.notes,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "notes",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.notes",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "buttons-content",
                                                  class: {
                                                    "is-active":
                                                      _vm.buttonsTabs ===
                                                      "competition-cycle",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [_vm._v("Zawody w cyklu")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "buttons-content",
                                                  class: {
                                                    "is-active":
                                                      _vm.buttonsTabs ===
                                                      "orders",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "text" },
                                                    [_vm._v("Zamówienia")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isMobile && _vm.form && _vm.form.id
                                    ? _c(
                                        "div",
                                        { staticClass: "form-col form-col-" },
                                        [
                                          _c(
                                            "base-button",
                                            {
                                              staticClass:
                                                "btn-short btn-outline-primary btn-remove-user",
                                              class: {
                                                "is-waiting": _vm.isWaiting,
                                              },
                                              attrs: { btn9: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteUserFromList(
                                                    _vm.form.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Usuń uczestnika")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "d-none",
                                  attrs: { type: "submit" },
                                },
                                [_vm._v("for keyboard action")]
                              ),
                              _vm.isWaiting
                                ? _c("div", { staticClass: "loading" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "lds-ellipsis lds-ellipsis--center",
                                      },
                                      [_c("div"), _c("div"), _c("div")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _vm.isMobile
                  ? [
                      _c(
                        "div",
                        { staticClass: "btn-wrap" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "btn-short",
                              class: { "is-waiting": _vm.isWaiting },
                              attrs: { btn9: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.addNewUser.closeModal()
                                },
                              },
                            },
                            [_vm._v("Anuluj")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "btn-long",
                              class: { "is-waiting": _vm.isWaiting },
                              attrs: { btn1: "" },
                              on: { click: _vm.formSubmit },
                            },
                            [_vm._v("Zapisz")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        { staticClass: "btn-wrap" },
                        [
                          _vm.form && _vm.form.id
                            ? _c(
                                "base-button",
                                {
                                  staticClass: "btn-short btn-outline-primary",
                                  class: { "is-waiting": _vm.isWaiting },
                                  attrs: { btn9: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteUserFromList(_vm.form.id)
                                    },
                                  },
                                },
                                [_vm._v("Usuń uczestnika")]
                              )
                            : _vm._e(),
                          _c(
                            "base-button",
                            {
                              staticClass: "btn-short",
                              class: { "is-waiting": _vm.isWaiting },
                              attrs: { btn9: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.addNewUser.closeModal()
                                },
                              },
                            },
                            [_vm._v("Anuluj")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "btn-long",
                          class: { "is-waiting": _vm.isWaiting },
                          attrs: { btn1: "" },
                          on: { click: _vm.formSubmit },
                        },
                        [_vm._v("Zapisz")]
                      ),
                    ],
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("modal", {
        ref: "payLinkModal",
        attrs: { id: "payLinkModal", bodyClass: "h-auto" },
        on: {
          "modal-close": function ($event) {
            _vm.playerPayLink = ""
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Link do płatności online")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "downloadLink" }, [
                  _c("div", { staticClass: "downloadLinkWrap" }, [
                    _c("div", { staticClass: "downloadLinkInput" }, [
                      _vm._v(" " + _vm._s(_vm.playerPayLink) + " "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        class: { success: _vm.playerPayCopied },
                        on: { click: _vm.copyPayLinkTrigger },
                      },
                      [
                        _vm.playerPayCopied
                          ? [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "32",
                                    height: "32",
                                    viewBox: "0 0 32 32",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M14.5 18.7929L21.1464 12.1464C21.3417 11.9512 21.6583 11.9512 21.8536 12.1464C22.0488 12.3417 22.0488 12.6583 21.8536 12.8536L14.8536 19.8536C14.6583 20.0488 14.3417 20.0488 14.1464 19.8536L11.1464 16.8536C10.9512 16.6583 10.9512 16.3417 11.1464 16.1464C11.3417 15.9512 11.6583 15.9512 11.8536 16.1464L14.5 18.7929Z",
                                      fill: "white",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" Skopiowany "),
                            ]
                          : [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M11.686 12.8601C11.8818 12.6653 11.8827 12.3488 11.6879 12.153C10.7787 11.2388 10.779 9.76187 11.6885 8.84801L13.7771 6.74933C14.74 5.78184 16.3001 5.763 17.286 6.70698C17.3177 6.73733 17.3485 6.76859 17.3784 6.80073C18.2373 7.72367 18.1854 9.16814 17.2624 10.027L14.9982 12.1341C14.7961 12.3222 14.7847 12.6386 14.9728 12.8407C15.1609 13.0429 15.4773 13.0543 15.6795 12.8661L17.9437 10.7591C19.2709 9.52395 19.3456 7.44673 18.1105 6.11948C18.0675 6.07327 18.0232 6.02832 17.9776 5.98467C16.5982 4.66396 14.4154 4.69031 13.0683 6.04392L10.9797 8.1426C9.682 9.44651 9.68166 11.5538 10.9789 12.8581C11.1736 13.0539 11.4902 13.0548 11.686 12.8601ZM12.1529 11.14C11.9571 11.3348 11.9562 11.6514 12.151 11.8472C13.0596 12.7607 13.0584 14.237 12.1483 15.149L9.98051 17.3215C9.07574 18.2282 7.60864 18.2343 6.69608 17.335C5.7869 16.4387 5.77643 14.9751 6.67269 14.0659L8.85614 11.851C9.05 11.6544 9.04774 11.3378 8.85108 11.1439C8.65443 10.9501 8.33786 10.9523 8.144 11.149L5.96054 13.3639C4.67656 14.6664 4.69156 16.7632 5.99438 18.0475C7.29835 19.3325 9.39522 19.3238 10.6884 18.0278L12.8562 15.8553C14.1553 14.5534 14.157 12.446 12.86 11.142C12.6653 10.9462 12.3487 10.9453 12.1529 11.14Z",
                                      fill: "#fff",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" Skopiuj link "),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "base-button",
                  {
                    staticClass: "btn-short",
                    attrs: { btn9: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$refs.payLinkModal.closeModal()
                      },
                    },
                  },
                  [_vm._v("Zamknij")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-column mat24" }, [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("Trwa wczytywanie listy startowej."),
      ]),
      _c("div", { staticClass: "mat16 lds-ellipsis lds-ellipsis--center" }, [
        _c("div"),
        _c("div"),
        _c("div"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }